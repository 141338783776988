<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row">
              <div class="col-6">
                <h4>
                  <!--                  <router-link :to="{name:'Role'}">-->
                  <!--                    <i class="fas fa-arrow-left"></i>-->
                  <!--                  </router-link>-->
                  All Permission
                </h4>
              </div>
              <div class="col-6 text-right">
                <button v-if="checkIsAccessible('permission','create')" @click="showModal('create-permission')"
                        class="btn btn-primary ml-2">

                  Add New Permission
                </button>
              </div>
              <div
                  class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start"
              >
                <v-row>
                  <v-col cols="12" md="4">
                    <v-text-field
                        label="Title"
                        type="text"
                        dense
                        v-on:keyup.enter="getPermissions"
                        outlined
                        v-model="search.title"
                    />
                  </v-col>

                  <v-col cols="12" md="8" class="text-right">
                    <v-btn
                        @click.prevent="getPermissions"
                        v-on:keyup.enter="getPermissions"
                        class="btn btn-primary w-35"
                        style="color: #fff"
                        :loading="loading"
                    >
                      <v-icon small outlined>fas fa-search</v-icon>&nbsp; Search
                    </v-btn>
                  </v-col>
                </v-row>
              </div>

              <div class="col-12 mt-2">
                <table class="table table-stripe">
                  <thead>
                  <th>Name</th>
                  <th>Action</th>
                  </thead>
                  <tbody>
                  <tr v-for="(permission, index) of permissions" :key="index">
                    <td>{{ permission.name }}</td>
                    <td>
                      <span class="fas fa-trash" v-if="checkIsAccessible('permission','delete')" @click="deletePermission(permission)"></span>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div class="col-12 text-right">
                  <b-pagination
                      @input="getPermissions"
                      v-model="page"
                      :total-rows="total"
                      :per-page="perPage"
                      first-number
                      last-number
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <create-and-update ref="create-permission" @update_list="getPermissions"></create-and-update>
    </div>
  </v-app>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import PermissionService from "@/core/services/user/permission/PermissionService";
import CreateAndUpdate from "./CreateaAndUpdate"

const permissionService = new PermissionService();
export default {
  name: "permission",
  validations: {
    permission_name: {required}
  }, components: {
    CreateAndUpdate
  },
  data() {
    return {
      search: {},
      permissions: [],
      loading: false,
      page: null,
      total: null,
      perPage: null
    }
  },
  mounted() {
    this.getPermissions()
  },
  methods: {
    getPermissions() {
      permissionService.paginate(this.search, this.page).then(response => {
        this.permissions = response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
      });
    }, deletePermission(permission) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            permissionService.delete(permission.id).then(response => {
              this.getPermissions();
            });
          }
        }
      });
    }, showModal(modal) {
      this.$refs[modal].showModal();
    }
  }
}
</script>

<style scoped>

</style>
